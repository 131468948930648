<div class="container">
    <div class="row my-3 align-items-center">
        <div class="col-md-8">
            <h3>Selbst Trainieren</h3>
            <p>
                In diesem Abschnitt nehmen wir kein vortrainiertes Netz, sondern lassen das Training erst live
                stattfinden. Wir
                brauchen dazu eine große Menge an handschriftlichen Ziffern, die das Netz für das Training verwenden
                kann. Dazu
                verwenden wir die sogenannte "MNIST" Datenbank. Diese wird oft verwendet, um ein maschinelles
                Lernverfahren, das
                Zahlen erkennen soll, zu trainieren oder seine Leistung zu überprüfen. Jedem Bild einer Zahl ist dabei
                auch ihr
                tatsächlicher Wert zugeordnet.
            </p>
            <h4>Schritt 1: Datenbank laden</h4>
            <p>
                Zunächst muss die Datenbank mit den Bildern geladen werden.
                Glücklicherweise ist diese frei zugänglich und kann in Form einer großen Datei geladen werden.
                Die Datei ist eine sogenannte <a
                    href="https://storage.googleapis.com/learnjs-data/model-builder/mnist_images.png">Sprite-Datei</a>,
                das heißt sie ist ein großes Bild, worin die eigentlichen
                kleinen Bilder der Zahlen in einem Raster gespeichert sind.
                Erstellt wurde die Datenbank von der amerikanischen Behörde NIST,
                kurz für National Institute of Standards and Technology.
            </p>
            <div class="text-center">
                <button type="submit" class="btn btn-primary" [ngClass]="{'btn-success': stages[0]}"
                    (click)="loadData()">Datenbank Laden <span *ngIf="dataClass&&!stages[0]"
                        class="spinner-border spinner-border-sm"></span>
                </button>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <figure class="figure">
                <img src="assets/home-images/mnist.png.webp" class="figure-img img-fluid rounded mb-1">
                <figcaption class="figure-caption">MNIST Sprite-Datei</figcaption>
            </figure>
        </div>
    </div>

    <div class="row my-3 align-items-center" *ngIf="stages[0]">
        <div class="col-md-8">
            <h4>Schritt 2: Neuronales Netz erstellen</h4>
            <!-- <p>
                Nun kommt der wohlmöglich wichtigste Teil. Es muss über die Topologie des Netzes entschieden werden, das
                heißt, dass vorgegeben werden muss, wie das Netz auszusehen hat. Eine wichtige Rolle spielen dabei unter
                anderem die Anzahl der Schichten und der Knoten in jeder Schicht, aber auch die Aktivierung der
                einzelnen Neuronen. <br> Wenn du mit diesen Begrifflichkeiten noch nicht gut vertraut bist, schaue dir
                am besten noch einmal Kapitel 5 des Kurses Elements of AI an, dort werden diese näher Erklärt.
            </p>
            <p>
                In diesem Beispiel können zwei verschiedene Modelle für ein neuronales Netz verwendet werden: Ein
                <strong>Konvolutionales</strong> Modell oder ein <strong>Vollverknüpftes</strong> Modell.
                Wie bereits in Kapitel 5 erklärt wurde, eignen sich konvolutionale Netze besser für Bildverarbeitung und
                -erkennung,
                als ein vollverknüpftes Netz. Du kannst beide Modelle trainieren und ausprobieren. <br>
                Beide Modelle verwenden als Aktivierungsfunktion einen <a
                    href="https://de.wikipedia.org/wiki/Rectifier_(neuronale_Netzwerke)">Rectifier</a>
                und konvertieren in der letzten Schicht die Gewichte mittels <a
                    href="https://de.wikipedia.org/wiki/Softmax-Funktion">Softmax-Funktion</a> in Wahrscheinlichkeiten
                für die jeweiligen Zahlen.
                Der unterschied liegt in den inneren Schichten, wo das konvolutionale Modell von mehreren
                <a href="https://de.wikipedia.org/wiki/Convolutional_Neural_Network#Aufbau">konvolutionalen und
                    Pooling-Schichten</a>
                gebrauch macht, um die Bilder besser zu <em>verstehen</em>.
            </p> -->
            <p>
                Nun kommt der wichtigste Teil. Es muss über die Struktur des Netzes entschieden werden. Ein neuronales
                Netz besteht aus so genannten künstlichen Neuronen, die das Verhalten menschlicher Gehirnzellen
                nachbilden sollen. Die Neuronen sind in Schichten strukturiert. Bei der Bilderkennung erhalten die
                Neuronen Daten aus der vorherigen Schicht und berechnen daraus neue Daten, die sie an die nächste
                Schicht weiterreichen.
            </p>
            <p>
                Eine wichtige Rolle spielen dabei unter anderem die Anzahl der Schichten und der Knoten in jeder
                Schicht, aber auch die Berechnungsvorschriften der einzelnen Neuronen.
            </p>
            <p>
                In diesem Beispiel können zwei verschiedene Modelle für ein neuronales Netz verwendet werden: Ein
                <b>konvolutionales</b> Modell oder ein <b>vollverknüpftes</b> Modell.
                Du kannst beide Modelle trainieren und ausprobieren.
            </p>
            <ngb-accordion #acc="ngbAccordion">
                <ngb-panel>
                    <ng-template ngbPanelTitle>
                        <span> <b>Details zu den Modelltypen</b> </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div class="col-md">
                                <h6>
                                    Vollverknüpftes Modell:
                                </h6>
                                <p>
                                    Ein neuronales Netz, bei dem alle Neuronen einer Schicht mit
                                    allen Neuronen der nächsten Schicht verknüpft sind. So genannte Perzeptren, einfache
                                    Netze, die z.B. erkennen können, ob ein Bild eine Katze zeigt oder nicht, sind oft
                                    als vollverknüpfte Netze realisiert.
                                </p>
                                <!-- <figure class="figure">
                                    <img src="{{basePath}}/neural-net.png" class="figure-img img-fluid rounded mb-1">
                                    <figcaption class="figure-caption">placeholder - copyright</figcaption>
                                </figure> -->
                            </div>
                            <div class="col-md">
                                <h6>
                                    Konvolutionales Modell:
                                </h6>
                                <p>
                                    Vollverknüpfte Netze sind darauf angewiesen, dass zu erkennende
                                    Bilder ungefähr dieselbe Perspektive haben. Ein vollverknüpftes neuronales Netz, das
                                    anhand von Passfotos darauf trainiert wurde, Gesichter zu erkennen, würde an
                                    Gruppenfotos oder Fotos, auf denen der Mensch ganz zu sehen ist, scheitern.
                                    Konvolutionale Netze identifizieren solche Muster auch in kleineren oder größeren
                                    Bildausschnitten und sind daher besser für die Bildbearbeitung und -erkennung
                                    geeignet.
                                </p>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="btn-group-vertical d-flex my-2">
                <button type="submit" class="btn btn-primary" [disabled]="!stages[0]"
                    [ngClass]="{'btn-success': stages[1] && modelType === 'conv'}"
                    (click)="createModel('conv')">Konvolutionales Modell</button>
                <button type="submit" class="btn btn-primary" [disabled]="!stages[0]"
                    [ngClass]="{'btn-success': stages[1] && modelType === 'dense'}"
                    (click)="createModel('dense')">Vollverknüpftes Modell</button>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <figure class="figure">
                <img src="{{basePath}}/neural-net.png.webp" class="figure-img img-fluid rounded mb-1">
                <figcaption class="figure-caption">Einfaches neuronales Netz</figcaption>
            </figure>
        </div>
    </div>

    <div class="row my-3 align-items-center" *ngIf="stages[1]">
        <div class="col-md-auto">
            <h4>Schritt 3: Training</h4>
            <p>
                Die Daten sind geladen, und die Modelle stehen bereit. Nun muss das neuronale Netz trainiert werden.
                Hierfür werden die Daten zunächst in zwei Teile getrennt, Trainingsdaten und Testdaten.
            </p>
            <!-- <p>
                Letztere werden,
                wie bereits in Kapitel 4 erklärt, gebraucht, damit wir bemerken, wenn in dem Netz Überanpassung
                stattfindet, also damit das Modell allgemein gute Vorhersagen treffen kann, auch außerhalb der
                Trainingsdaten.
            </p> -->
            <p>
                Die Trainingsdaten werden zum Lernen genutzt: das neuronale Netz erhält die Bilder der handschriftlich
                geschriebenen Ziffern und dazu die Information, welche Ziffern dort zu sehen sind, und lernt anhand
                dessen, die Ziffern zu identifizieren.
            </p>
            <p>
                Die Testdaten werden danach genutzt, um zu überprüfen, wie gut das trainierte Netz seine Aufgabe
                erledigt.
            </p>
            <p>
                Nun müssen zwei wichtige Parameter gesetzt werden: die Batchsize
                <select class="form-select form-select-sm mx-2 inline-select" type="number" [(ngModel)]="trainBatchSize">
                    <option disabled>Batchsize</option>
                    <option *ngFor="let x of availBatchSizes" [ngValue]="x">{{x}}</option>
                </select>
                und die Anzahl der Epochen
                <select class="form-select form-select-sm mx-2 inline-select" type="number" [(ngModel)]="trainEpochs">
                    <option disabled>Epochs</option>
                    <option *ngFor="let x of availEpochs" [ngValue]="x">{{x}}</option>
                </select>.
                Von diesen Parametern hängt ab, wie viele Schritte das Training benötigt.
                Die <b>Batchsize</b> gibt an, wie viele Bilder pro Trainingsschritt von dem Modell verarbeitet werden,
                während dieses optimiert wird. Die <b>Epochen</b> geben an, wie oft alle Trainingsdaten insgesamt
                durchgearbeitet werden.
            </p>
            <p>
                Das bedeutet je größer die Batchsize, desto mehr Bilder werden auf einmal verarbeitet. Das sorgt für
                mehr Rechenaufwand und dauert länger - kann aber auch besser Ergebnisse erziehlen.
                Ebenso führen mehr Epochen zu mehr Rechenaufwand, da bei 10 Epochen, jedes Bild auch 10 mal verarbeitet
                wird.
            </p>
            <p>
                Bei einer Batchsize von <code class="px-1">{{trainBatchSize}}</code> und <code
                    class="px-1">{{trainEpochs}}</code>
                Epochen umfasst das Training <code
                    class="px-1">{{(trainDataLength / trainBatchSize) * trainEpochs * 0.85 + 0.5 | number:'1.0-0'}}</code>
                Schritte. Das kommt dadurch, da von den <code class="px-1">{{trainDataLength | number:'1.0-0'}}</code>
                Bildern 15% zum Testen verwendet werden und nicht für das Training.
            </p>
            <!-- <p>
                Nun müssen zwei wichtige Parameter gesetzt werden: die Batchsize
                <select type="number" class="mx-2" [(ngModel)]="trainBatchSize">
                    <option disabled>Batchsize</option>
                    <option *ngFor="let x of availBatchSizes" [ngValue]="x">{{x}}</option>
                </select>
                und die Anzahl der Epochen
                <select type="number" class="mx-2" [(ngModel)]="trainEpochs">
                    <option disabled>Epochs</option>
                    <option *ngFor="let x of availEpochs" [ngValue]="x">{{x}}</option>
                </select>.
                <br>
                Die Batchsize gibt an, wie viele Bilder nacheinander von dem Modell verarbeitet werden, bis dieses
                Optimiert wird. Die Epochen geben an, wie oft alle Trainingsdaten durchgearbeitet werden. Das bedeutet
                eshey, I am encountering network problems (wifi and cellular drops connection) on los 18.1 enchilada. the xda post lists WFD as not working, is that my problem? thank youigt.
                Daraufhin wird das Modell noch einmal mit den Testdaten überprüft. <br>
                Das Ergebnis des Trainings kann auf dem Graphen beobachtet werden. Dort werden für jeden Schritt die
                Genauigkeit des Modells, also wie viel Prozent der Bilder korrekt zugeordnet wurden, gespeichert.
                Außerdem wird abschließend das letzte Ergebnis des Trainings und des Tests angezeigt.
            </p> -->

            <ngb-alert *ngIf="trainingRunning && !hideTrainWarn" [dismissible]="true" (closed)="hideTrainWarn = true">
                <strong>Achtung!</strong> Während des Trainings kann es dazu kommen, dass der
                Webbrowser einfriert. In dem Fall einfach kurz warten. Falls sich nach 10 Sekunden nichts verändert,
                schließe den Browsertab und lade die Seite erneut.
            </ngb-alert>

            <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-primary my-2"
                    [disabled]="!stages[1] || (trainingRunning && !trainingDone)" [ngClass]="{'btn-success': stages[2]}"
                    (click)="trainModel()">
                    Trainieren <span *ngIf="trainingRunning" class="spinner-border spinner-border-sm"></span>
                </button>
            </div>

            <div class="col text-center">

                <canvas baseChart width="400" height="100" [data]="lineChartData" [options]="lineChartOptions"
                    [type]="lineChartType"></canvas>
                <div class="row justify-content-center my-2" *ngIf="trainingRunning || trainingDone">
                    <p>
                        <ngb-progressbar [showValue]="true" type="success" [value]="trainBatchCount"
                            [max]="trainTotalBatches"></ngb-progressbar>
                    </p>
                </div>
            </div>

            <div class="my-2" *ngIf="trainingDone">
                <p>
                    Dein neuronales Netzwerk ist fertig trainiert!
                    <!-- Finale Genauigkeit aus dem Training: {{trainValidationAcc | percent}} <br> -->
                    Finale Genauigkeit aus dem Testen: {{testValidationAcc | percent}}
                    Jetzt kannst du dein selbst trainiertes neuronales Netzwerk ausprobieren, nachdem du es übertragen
                    hast. Mit der Maus oder einem
                    Touchscreen kannst du auf die schwarze Fläche Zahlen malen. Nach einem Klick auf „Auswerten“
                    versucht das neuronale Netz, diese zu erkennen.
                </p>
            </div>

        </div>
    </div>
    <!--
    <div class="row my-3 align-items-center" *ngIf="stages[2]">
        <div class="col-md-8">
            <h4>Schritt 4: Modell Übertragen</h4>
            <p>
                Zu allerletzt muss das trainierte Modell übertragen werden, damit es ausprobiert werden kann.
                Zahlen können danach auf dem schwarzen Feld gezeichnet werden und das Modell versucht diese zu erkennen,
                nachdem der Auswerten Knopf betätigt wurde.
            </p>
        </div>
        <div class="col-md-4 text-center">
            <button type="submit" class="btn btn-primary" [disabled]="!stages[2]" [ngClass]="{'btn-success': stages[3]}"
                (click)="submitModel()">Modell Übertragen</button>
        </div>
    </div>
    -->
</div>