<div class="container pt-3">
    <div class="row flex-md-row-reverse">
        <div class="col-md-5 align-self-center">
            <div class="d-flex flex-column align-items-center">
                <figure class="figure">
                    <img src="assets/text/toxicity/arguing-on-the-internet-tall2.jpg.webp"
                        class="figure-img img-fluid rounded">
                    <figcaption class="figure-caption">Soziale Netzwerke müssen nicht zwangsweise sozial sein.
                    </figcaption>
                </figure>
            </div>
        </div>
        <div class="col-md-7">
            <h3>
                Beleidigungserkennung
            </h3>
            <p>
                Das Internet ist eine wuderbare Erfindung mit nahezu grenzenlosem Potential.
                Menschen können von überall auf der Welt mit minimalen Kosten kommunizieren und zusammenarbeiten.
                Leider hat anonyme Kommunikation auch einen großen Nachteil denn nicht jeder ist bereit sich online
                anständig zu verhalten, besonders weil es nunmal nicht die gleichen Konsequenzen mit sich bringt wenn
                man jemandem online auf den Schlips tritt verglichen mit dem offline Analogon.
                Fast alle massentauglichen sozialen Netzwerke benötigen deswegen irgendeine Art von inhaltlicher
                Moderation.
            </p>
            <p>
                Die Frage ist jedoch, wie eine solche Moderation auszusehen hat. Die tradizionelle Herangehensweise ist
                es die Moderation von Menschen durchführen zu lassen.
                Es stellt sich allerdings heraus, dass dies vielleicht nicnt die beste Idee ist. In einem <a
                    href="https://www.washingtonpost.com/technology/2020/05/12/facebook-content-moderator-ptsd/"
                    target="_blank">Artikel
                    der Washington Post</a> (<a href="http://archive.today/ni59w" target="_blank">Archiv</a>)
                wird eine Moderatorin vorgestellt, die bei Facebook arbeitete und täglich Beitrage der Nutzer überprüft
                hat. Das Resultat war ein Gerichtsverfahren, wo die ehemalige Angestelle $52 Millionen einforderte für
                die resultierende posttraumatische Belastungsstörung.
                Wir benötigen also eine andere Lösung, die einerseits auf Millionen von Nutzer skaliert und gleichzeitig
                nicht die Mitarbeiter traumatisiert.
            </p>
            <p>
                Die künstliche Intelligenz kommt zur Rettung!
                Neben Bildern ist es nämlich ebenso möglich Text zu klassifizieren.
                Der Ansatz ist hierbei jedoch etwas aufwändiger. Denn Text kann nicht ohne weiteres als Eingabe für ein
                neuronales Netz verwendet werden - hierfür brauchen wir numerische Werte, keine Wörter.
                Der Fachbegriff für diese Transformation von Text in numerischen Eingaben sind Embeddings.
                Embeddings bilden Wörter auf Vektoren ab, welche von einem neuronalen Netz verwendet werden können um
                den Kontext und die Bedeutung zu erlernen.
            </p>
        </div>
    </div>

    <div class="row mb-4 justify-content-center">
        <div class="col-xl-8">
            <figure class="figure">
                <img src="assets/text/toxicity/linear-relationships.svg" class="figure-img img-fluid rounded">
                <figcaption class="figure-caption">Embeddings bilden Kontext und Bedeutung von Wörtern in einen
                    Vektorraum ab. Hier zum Beispiel die Bedeutung von Geschlechter von Nomen und Zeitformen von Verben.
                </figcaption>
            </figure>
        </div>
    </div>

    <div class="row my-3">
        <div class="col">
            <h4>Ein Modell für Beleidigungen</h4>
            <p>
                Um Inhalte zu moderieren ist es notwendig, dass wir ein neuronales Netz so trainieren, sodass es in der
                Lage
                ist Profanitäten zu erkennen.
                Ein beliebter Typ von neuronalen Netzen für Textverarbeitung sind Transformer. Diese zeichnen sich
                dadurch aus,
                dass sie nicht nur einzelne Eingaben verarbeiten, wie es bei Bild-Klassifizierungen üblich ist, sonder
                auch die vorherigen Eingaben.
                Hierdurch ist es möglich ein Modell zu trainieren, dass in der Lage ist den Kontext von Wörtern in einem
                Satz zu erkennen.
                Das Resultat ist ein Modell, welches Sätze als Eingaben empfängt und als Ausgabe eine Bewertung abgibt,
                ob
                es sich um einen beleidigenden Text handelt.
                Das hier verwendete Modell besteht aus einer Embedding Komponente, dem <a
                    href="https://arxiv.org/pdf/1803.11175.pdf">Universal Sentence Encoder</a> und einer Bewertungs
                Komponente, die mit 2 millionen Kommentaren aus sozialen Netzen trainiert wurde, um Beleidugungen zu
                erkennen.
            </p>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-md-6">
            <div class="input-group">
                <span class="input-group-text">Texteingabe in Englisch</span>
                <input type="text" class="form-control" placeholder="" #textInput
                    (change)="onTextChange(textInput, table)">
                <button class="btn btn-primary" [disabled]="!modelLoaded" type="button"
                    (click)="evaluateText(textInput.value);textInput.value = '';">Analysieren</button>
                <button class="btn btn-warning" [disabled]="!modelLoaded" type="button"
                    (click)="tableData = []">Zurücksetzen</button>
            </div>
        </div>
    </div>

    <div class="row my-3">
        <div class="col">
            <table #table class="table">
                <thead>
                    <tr>
                        <th scope="col">Text</th>
                        <th scope="col" *ngFor="let l of labels">{{labelTranslation[l]}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of tableData">
                        <th scope="row">{{row.text}}</th>
                        <td *ngFor="let l of labels"
                            [ngClass]="{'toxic': row[l], 'ntoxic': !row[l], 'unsuretoxic': row[l] === null}">

                            <div>
                                {{getTextFromResult(row[l])}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3 *ngIf="tableData.length === 0" class="text-center">Gib einen Text ein, um ihn auf Beleidigungen zu
                berechnen.</h3>
        </div>
    </div>
    <div class="row justify-content-center mt-5 mb-3">
        <div class="col-md-8">
            <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="">
                <ngb-panel>
                    <ng-template ngbPanelTitle>
                        <span>
                            <h4>Aufgabe 1</h4>
                        </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        Welche sind die wichtigsten Vorteile der Verwendung von Machine Learning-Algorithmen für die Textmoderation?
                    </ng-template>
                </ngb-panel>
                <ngb-panel>
                    <ng-template ngbPanelTitle>
                        <span>
                            <h4>Aufgabe 2</h4>
                        </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        Wie vergleicht sich die auf Machine Learning basierende Textmoderation mit der menschlichen Moderation hinsichtlich Genauigkeit und Geschwindigkeit?
                    </ng-template>
                </ngb-panel>
                <ngb-panel>
                    <ng-template ngbPanelTitle>
                        <span>
                            <h4>Aufgabe 3</h4>
                        </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        Überlege dir welche Probleme auftreten können wenn wir die Moderation vollständig unserer KI
                        überlassen.
                        Is es eine gute Idee einem Computer volle Kontrolle darüber zu geben, welche Inhalte erlaubt
                        sind und welche nicht?
                        Was für eine Rolle könnte <a href="https://de.wikipedia.org/wiki/Adversarial_Attack">Adversarial
                            machine learning</a> hier spielen?
                    </ng-template>
                </ngb-panel>
                <ngb-panel>
                    <ng-template ngbPanelTitle>
                        <span>
                            <h4>Aufgabe 4</h4>
                        </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        In welchen Fällen brauchen wir eventuell einen Menschen, der die Ergebnisse der KI noch einmal
                        überprüft?
                        Kannst du dir ein Scenario vorstellen, wo ein Beitrag in Sozialen Medien fälschlicherweise als
                        Beleidigung erkannt und entfernt wird?
                    </ng-template>
                </ngb-panel>
                <ngb-panel>
                    <ng-template ngbPanelTitle>
                        <span>
                            <h4>Aufgabe 5</h4>
                        </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        Finde heraus, wie "content moderation" in den gängigen großen Sozialen Netzwerken gehandhabt
                        wird.
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
</div>