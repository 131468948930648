<div id="intro-section">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="mb-4">
                    <h1>Herzlich willkommen im KI-Labor!</h1>
                    <p>
                        Diese Webseite ermöglicht eine praktische Einführung in das Gebiet des maschinellen Lernens,
                        eine Methode der Künstlichen Intelligenz (KI). Im Inhalt knüpft diese Seite an den Massive Open
                        Online Course Elements of AI an, aber alle Übungen können auch ohne Kenntnis des MOOC bearbeitet
                        werden.
                    </p>
                </div>
                <div>
                    <h3>Wer, was, warum?</h3>
                    <p>
                        Entwickelt wurde diese Seite ursprünglich an der Christian-Albrechts-Universität zu Kiel als
                        Teil einer Bachelorarbeit. Nun wird diese Webseite von der Arbeitsgruppe für Didaktik
                        weiterentwickelt.
                    </p>
                    <p>
                        Insgesamt bietet diese Seite eine Vielzahl von Komponenten, die auch eine Vielzahl von Bereichen
                        der künstlichen Intelligenz abdecken. Mehr dazu kannst du unten erfahren.
                    </p>
                    <p>
                        Das Ziel ist es, eine begleitende Lernumgebung zu ermöglichen, womit Schüler den praktischen
                        Umgang mit maschinellem Lernen erfahren können, während Sie den MOOC <a class="anchor-on-blue" href="https://course.elementsofai.com/de/">Elements of AI</a> bearbeiten.
                    </p>
                </div>

            </div>
            <div class="col-md-5 col-lg-4 d-flex flex-column justify-content-around justify-content-md-center">
                <figure class="figure mx-5 my-5 my-lg-3 mx-md-1">
                    <img src="assets/branding/tf-norm-de-blackgreyfaku-rgb-0720.png.webp"
                        class="figure-img img-fluid rounded" alt="CAU-Logo">
                    <figcaption class="figure-caption figcaption-on-blue">Entwickelt von der
                        Christian-Albrechts-Universität zu
                        Kiel</figcaption>
                </figure>

                <figure class="figure mx-5 my-3 mx-md-1">
                    <img src="assets/branding/telekom_logo.png" class="figure-img img-fluid rounded img-padding-on-blue"
                        alt="Telekom-Logo">
                    <figcaption class="figure-caption figcaption-on-blue">Finanziert durch die Deutsche Telekom Stiftung
                    </figcaption>
                </figure>
            </div>
        </div>
    </div>
</div>
<div class="mt-5">
    <div class="container">
        <div>
            <h2>Welche Themen werden hier behandelt?</h2>
            <p>
                Maschinelles Lernen umfasst diverse Teilgebiete. Diese Seite ist in neun Lerneinheiten aufgeteilt,
                die die Teilgebiete „Überwachtes Lernen“ und „Bestärkendes Lernen“ thematisieren.
            </p>
        </div>

        <div>
            <span *ngFor="let tag of allTags; index as i" (click)="addOrRemove(tag)"
                [ngClass]="{'bg-success': activeTags.includes(tag), 'bg-danger': excludedTags.includes(tag)}"
                class="badge filter-pill-badge bg-primary">{{tag | titlecase}}</span>
        </div>
        <div class="d-flex justify-content-evenly row">
            <app-card *ngFor="let card of cardsData | callback:filterCards:activeTags:excludedTags" [title]="card.title"
                [text]="card.text" [srcPath]="card.srcPath" [video]="card.video" [routerLink]="card.routerLink">
            </app-card>
        </div>
    </div>
</div>