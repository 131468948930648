<div class="container pt-3">
  <!-- only display this prompt for the mooc in mnistb -->
  <div class="row mb-3" *ngIf="inPath('mnistb')">
    <div class="col text-center">
      <h4>
        Lies zunächst das Kapitel über neuronale Netze des <a href="https://course.elementsofai.com/de/5" target="_blank">Elements of AI
          Kurses</a> durch.
        </h4>
      <button type="button" class="btn btn-primary" (click)="mnistbReadFirst = true" [ngClass]="{'btn-success': mnistbReadFirst}">Fertig?</button>
    </div>
  </div>

  <div class="row mb-3" *ngIf="mnistbReadFirst">
    <div class="col-auto">
      <h3>MNIST Zahlenerkennung</h3>
      <p>
        In diesem Teil der Webseite kannst du mit einem neuronalen Netz experimentieren, dass handschriftliche Ziffern
        erkennt. Diese Technik kommt zum Beispiel bei der automatischen Sortierung von Briefen zum Einsatz.
      </p>
      <p>
        Neuronale Netze müssen anhand von vielen Daten für Ihren späteren Einsatzzweck trainiert werden. Du wirst hier
        zuerst ein schon fertig trainiertes Netz verwenden und kannst dann auch den Trainingsprozess selbst ein wenig
        genauer unter die Lupe nehmen.
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-primary" [ngClass]="{'btn-success': modelType === 'pre'}"
          (click)="loadPretrainedModel()">Okay, los geht's
          <span *ngIf="currentlyLoading" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="stages[0]" class="my-5">
    <p>
      Male hier mit deiner Maus (oder einem Finger oder Stift bei einem Touchscreen) eine Ziffer in das Feld und klicke
      Auswerten um zu sehen, welche Ziffer das Netz erkennt.
    </p>
    <app-mnist-eval-model [model]="model"></app-mnist-eval-model>
    <div class="row justify-content-center my-3">
      <div class="col-md-8">
        <ngb-accordion [closeOthers]="false" #acc="ngbAccordion" activeIds="">
          <ngb-panel>
            <ng-template ngbPanelTitle>
              <span>
                <h4>Aufgabe 1</h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Was bedeutet die grafische Anzeige der Auswertung und wie wird die darunter angezeigte Vorhersage daraus
              ermittelt? Wie kannst du deine Vermutung überprüfen?
            </ng-template>
          </ngb-panel>
          <ngb-panel>
            <ng-template ngbPanelTitle>
              <span>
                <h4>Aufgabe 2</h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Versuche eine Eingabe für das neuronale Netz (also eine gemalte Ziffer) zu erzeugen, bei der sich das Netz
              nicht eindeutig entscheiden kann und eine andere Eingabe bei der die Entscheidung sehr eindeutig ist.
            </ng-template>
          </ngb-panel>
          <ngb-panel>
            <ng-template ngbPanelTitle>
              <span>
                <h4>Aufgabe 3</h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Male verschiedene Ziffern auf ein Blatt Papier und merke dir, welche Ziffer du jeweils meintest. Lass dann
              jemand anderen deine Ziffern erkennen. Wieviele Fehler hat die Person gemacht? Wieviele Fehler macht das
              neuronale Netz, wenn du die Ziffern so genau wie möglich in der Zeichenfläche nachzeichnest?
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>

    <div class="row justify-content-center mt-3">
      <ngb-alert *ngIf="benchmarkTooSlow && !benchmarkWarningClosed" [dismissible]="true" (closed)="benchmarkWarningClosed = true">
        <strong>Achtung!</strong> Die Leistung dieses Computers könnte für das Training eines neuronalen Netzes nicht ausreichend sein.
        Für das Training sind eine schnelle CPU und mindestens 4GB Arbeitsspeicher eine Voraussetzung, da die Berechnungen sehr umfangreich sind.
      </ngb-alert>
    </div>

    <div class="row justify-content-center mb-3">
      <div class="col-auto">
        <button type="button" class="btn btn-primary" [ngClass]="{'btn-success': stages[1]}"
          (click)="stages[1] = true">Das Netz selbst trainieren
          <span *ngIf="currentlyLoading" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
    </div>
  </div>

  <div class="row my-3" *ngIf="stages[1]">
    <app-tutorial (modelCreated)="importCustomModel($event)"></app-tutorial>
  </div>

  <div class="my-3" *ngIf="stages[1] && gotCustomModel">
    <app-mnist-eval-model [model]="customModel"></app-mnist-eval-model>
  </div>

  <div class="row justify-content-center mt-5 mb-4" *ngIf="stages[1] && gotCustomModel">
    <div class="col-md-6">
      <figure class="figure">
        <video src="assets/mnist-images/mnist-convnet.webm" class="figure-img img-fluid rounded mb-1" alt="thumbnail"
          autoplay muted loop></video>
        <figcaption class="figure-caption">Illustration unseres Neuronalen Netzes, von Marijn van Vliet</figcaption>
      </figure>
    </div>
  </div>

  <div class="row justify-content-center mt-5 mb-4" *ngIf="stages[1] && gotCustomModel">
    <div class="col-md-8">
      <ngb-accordion [closeOthers]="false" #acc="ngbAccordion" activeIds="">
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Aufgabe 4</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            Wie gut funktioniert das selbst trainierte Netz im Vergleich zum Vortrainierten? Unterscheide dabei zwischen
            der tatsächlichen Vorhersage und der Sicherheit dieser Vorhersage.
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Aufgabe 5</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            Experimentiere mit verschiedenen Werten beim Training und prüfe, ob sich die Genauigkeit verbessert. Wie
            könnte man möglichst systematisch prüfen, wie gut ein trainiertes Netz am Ende funktioniert um die optimalen
            Parameter zu ermitteln?
          </ng-template>
        </ngb-panel>
        <ngb-panel *ngIf="!inPath('mnista', 'mnistb')">
          <ng-template ngbPanelTitle>
            <span>
              <h4>Aufgabe 6</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            Versuche anhand des bisher gesehenen zu beschreiben, was man alles tun muss, damit ein neuronales Netz
            Gesichter auf Bildern erkennt.
          </ng-template>
        </ngb-panel>
      </ngb-accordion>

      <!-- more info display in mnista -->
      <div class="mt-3 text-center" *ngIf="inPath('mnista')">
        <ng-template #popContent>Um mehr über die Funktionsweise und das Training neuronaler Netze zu erfahren, arbeite
          jetzt bitte das Kapitel des <a href="https://course.elementsofai.com/de/5" target="_blank">Elements of AI
            Kurses</a> durch.</ng-template>
        <button type="button" class="btn btn-outline-primary" [ngbPopover]="popContent" popoverTitle="Elements of AI">
          Mehr erfahren
        </button>
      </div>

      <!-- survey link -->
      <div class="mt-3 text-center" *ngIf="inPath('mnista', 'mnistb')">
        <h2>
          <a href="https://levumi.informatik.uni-kiel.de/limesurvey/index.php/954851" target="_blank">Fertig? Dann
            beantworte jetzt abschließend bitte kurz einige Fragen in der folgenden anonymen Befragung.</a>
        </h2>
      </div>
    </div>
  </div>
</div>