<div class="container pt-3">
    <h3>Tic Tac Toe</h3>
    <div class="row my-3 align-items-center">
        <div class="col-md-10">
            <p>
                Kann der Computer auch Spiele erlernen? Die kurze Antwort ist: Ja! Tatsächlich gibt es sogar eine eigene
                Kategorie des maschinellen Lernens, die sich ausschließlich mit dem spielerischen Lernen von Problemen
                beschäftigt, und zwar bestärkendes Lernen. Hierbei bekommt der Computer eine einfache Aufgabe: Gewinne
                das Spiel! Wie dies bewerkstelligt wird, ist dabei egal, solange der Computer sich an die Regeln des
                Spiels hält.
            </p>
            <p>
                Ein Spiel, welches sich gut hierfür eignet ist Tic Tac Toe. Die regeln sind sehr einfach und wir können
                dabei zusehen, wie der Computer lernt und seine Strategie verbessert während er gegen uns spielt.
            </p>
        </div>
    </div>

    <!-- task 1 -->
    <div class="row my-3 align-items-center">
        <div class="col-md-10">
            <h4>Aufgabe 1:</h4>
            <p>Spiele 30 Spiele und betrachte dabei in jedem Zug den Spielbaum, der rechts dargestellt wird, und das
                Koordinatensystem, das unter dem Spielfeld dargstellt wird, genau. Beantworte währenddessen die
                folgenden Fragen.
                <br />
                <strong>Hinweis:</strong> Achte darauf, dass du auch mindestens einmal „absichtlich“ verlierst und
                unentschieden spielst.
            </p>
        </div>
    </div>

    <ul ngbNav #nav="ngbNav" [activeId]="taskOneNav" class="nav-tabs">
        <li *ngFor="let task of tasks" [ngbNavItem]="task.id" [disabled]="task.id > taskProgress">
            <a ngbNavLink><b>{{task.title}}</b></a>
            <ng-template ngbNavContent>

                <div class="tab-pane fade show active" id="nav-1a_task" role="tabpanel" aria-labelledby="nav-home-tab">
                    <p>
                        {{task.text}}
                    </p>
                    <div class="form-check">
                        <input class="form-check-input myCheckbox" type="checkbox" value="" id="check1a"
                            [ngModel]="task.id < taskProgress" [disabled]="task.id < taskProgress"
                            (change)="advanceTask()">
                        <label class="form-check-label myCheckboxLabel" for="check1a">
                            Fertig?
                        </label>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <!-- task 2 -->
    <div class="row my-3 align-items-center" id="task2" *ngIf="taskProgress >= numberOfTasks - 1">
        <div class="col-md-10">
            <h4>Aufgabe 2:</h4>
            <p>Versuche nun ausgehend von deinen Beobachtungen zu beschreiben, wie der Agent spielt und lernt. Gehe
                dabei insbesondere darauf ein, wie sich das Verhalten mit der Anzahl der Spiele möglicherweise
                verändert. Beantworte dann, ob der Agent nach einer Vielzahl von Spielen noch verlieren kann. Begründe
                deine Antwort.
            </p>
            <div class="form-check">
                <input class="form-check-input myCheckbox" type="checkbox" value="" id="check2"
                    [disabled]="numberOfTasks - 1 < taskProgress" (change)="advanceTask()">
                <label class="form-check-label myCheckboxLabel" for="check2">
                    Fertig?
                </label>
            </div>
        </div>
    </div>

    <!-- task 3 -->
    <div class="row my-3 align-items-center" id="task3" *ngIf="taskProgress >= numberOfTasks - 0">
        <div class="col-md-10">
            <h4>Aufgabe 3:</h4>
            <p>Versuche nun, ausgehend von deiner Beschreibung, einen Algorithmus in natürlicher Sprache zu formulieren,
                der das Verhalten des Agenten beschreibt.
            </p>
            <div class="form-check">
                <input class="form-check-input myCheckbox" type="checkbox" value="" id="check3"
                    [disabled]="numberOfTasks - 0 < taskProgress" (change)="advanceTask()">
                <label class="form-check-label myCheckboxLabel" for="check3">
                    Fertig?
                </label>
            </div>
        </div>
    </div>


    <!--Kontrollelemente -->
    <div class="row flex-nowrap">
        <div class="col-md" style="text-align: center;">
            <button id="restart" type="button" class="btn btn-primary buttons" [disabled]="buttonsDisabled"
                (click)="resetGame()">Neues Spiel
                <span class="tooltiptext">Startet ein neues Spiel</span>
            </button>
        </div>
        <div class="col-md" style="text-align: center;">
            <button id="perfectAgent" type="button" class="btn btn-primary buttons" [disabled]="buttonsDisabled"
                (click)="perfectAgentPlay()">Optimales Spiel
                <span class="tooltiptext">Spielt automatisch mit der besten Stategie</span>
            </button>
        </div>
        <div class="col-md" style="text-align: center;">
            <button id="reset" type="button" class="btn btn-warning buttons" [disabled]="buttonsDisabled"
                (click)="resetAll()">Zurücksetzen
                <span class="tooltiptext">Setzt alle Spiele und den Agenten in den Anfangszustand zurück</span>
            </button>
        </div>
    </div>

    <!--Spielfeld -->
    <div class="row flex-nowrap" style="margin-top: 3%;">
        <div class="col-md-1"></div>
        <div #boardSection class="col-md-3" id="board_section" style="text-align: center;">
            <div #boardSketch id="board"></div>
            <br>
            <span class="counter">
                Gespielte Spiele:
                <span id="gameCounter">0</span>
            </span>
            <br>
            <!--Diagram-->
            <div #diagramSketch id="diagram" style="margin-top: 4%;"></div>
        </div>
        <div #treeArea class="col-md-8 tree" id="tree_area" style="text-align: center; ">
            <div #treeSketch id="tree"></div>
        </div>
    </div>
</div>