<div class="container pt-3">

    <div class="row mb-3">
        <div class="col-md-6 col-lg-8">
            <h3 class="mb-3">Stlye Transfer mit GANs</h3>
            <h4>
                Was ist ein Style Transfer?
            </h4>
            <p>
                Wenn man ein Bild gegeben hat, und dessen Stil auf ein anderes Bild anwenden möchte,
                so kann dies durch maschinelles Lernen umgesetzt werden.
                Hierbei wird durch GANs ein Modell trainiert,
                welches versucht den Stil des Stilbildes so gut wie möglich zu repräsentieren.
                Anschließend wird dieses Modell auf beliebige andere Inputbilder angewandt.
                Das Ergebnis sollte dann eine Kombination aus Quell- und Stilbild sein.
            </p>
            <p>
                Hierbei bleibt die Struktur des Inputbildes erhalten und lediglich Farben und Muster des Stilbildes
                werden auf das Inputbild abgebildet.
                So kann zum Beispiel der Stil eines van Gogh Porträts auf ein Foto aus deiner Webcam projeziert werden.
            </p>
        </div>
        <div class="col-md-6 col-lg-4">
            <div class="">
                <app-gan-transfer-input #inputImg [imgSrc]="imageInputSrc" [inputDisabled]="!buttonsEnabled"
                    [listOfImages]="preImagesInput" [description]="'Input Bild'">
                </app-gan-transfer-input>
            </div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6 col-lg-8">
            <h4>
                Wie funktioniert das Ganze?
            </h4>
            <p>
                Zuerst musst du ein Inputbild auswählen. Hierfür stehen die einige Optionen zur Verfügung.
                Du kannst zum Beispiel deine Webcam verwenden, um ein Bild aufzunehmen.
                Oder du lädst einfach ein Foto als Datei hoch.
                Alternativ kannst du auch aus einer Reihe von vorgegebenen Bildern wählen.
                Das Inputbild ist jenes, welches den Style Transfer erfahren soll.
            </p>
            <p>
                Nun kannst du erneut auf die gleiche Weise ein Stilbild hochladen oder ein vorgegebenes Bild verwenden.
                Besonders gute erbebnisse kannst du erziehlen wenn du ein Stilbild verwendest mit einem sehr markanten
                Stil, wie es bei dem "Hafen" oder der "Zeichnung" der Fall ist. Hier ist der Transfer sehr gut
                erkennbar.
            </p>
        </div>
        <div class="col-md-6 col-lg-4">
            <div class="">
                <app-gan-transfer-input #style1 [imgSrc]="imageStyleLeftSrc" [inputDisabled]="!buttonsEnabled"
                    [listOfImages]="preImagesStyle" [description]="'Erster Style'">
                </app-gan-transfer-input>
            </div>
            <br>
            <div [ngClass]="{'d-none': !combineStyles}">
                <div class="">
                    <app-gan-transfer-input #style2 [imgSrc]="imageStyleRightSrc" [inputDisabled]="!buttonsEnabled"
                        [listOfImages]="preImagesStyle" [description]="'Zweiter Style'">
                    </app-gan-transfer-input>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-lg-8">
            <p>
                Wenn du beide benötigten Bilder hinzugefügt hast, kannst du nun die Stylestärke angeben.
                Die Stylestärke beschreibt, wie groß der Einfluss des Stils auf das Inputbild sein soll.
                Bei einer geringen Stylestärke wird das Inputbild nur minimal verändert, während eine 100% Stylestärke
                bedeutet, dass das Modell auch zu 100% die Ausgabe beeinflusst.
            </p>
            <p>
                Je nachdem weilche Bilder verwendet werden, können verschiedene Werte zu verschiedenen Ergebnissen
                führen - probiere es einfach aus!
            </p>
            <p>
                Falls der Algorithmus zügig arbeitet und nicht zu lange braucht, kannst du auch die Modell Qualität
                erhöhen, wodurch die Ergebnisse mehr Details enthalten sollten. Außerdem kannst du einen zweiten Stil
                verwenden und so zwei Styles kombinieren.
            </p>
            <div [ngClass]="{'d-none': !outputGenerated}">
                <figure class="figure">
                    <figcaption class="figure-caption">Generiertes Bild</figcaption>
                    <canvas #outputCanvas></canvas>
                </figure>
            </div>


        </div>

        <div class="col-md-6 col-lg-4">
            <form>
                <div class="mb-3 form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="combineStyles"
                        [ngModelOptions]="{standalone: true}">
                    <label class="form-check-label" for="exampleCheck1">Zwei Styles Kombinieren?</label>
                </div>
                <div class="mb-3">
                    <div class="form-floating">
                        <select #perfSelect class="form-select" id="floatingSelect"
                            aria-label="Floating label select example" (change)="updateModels(perfSelect.value)">
                            <option selected value="low">Schnelle Performance</option>
                            <option value="high">Hohe Qualität</option>
                        </select>
                        <label for="floatingSelect">Modell Qualität</label>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="customRange1" class="form-label">{{combineStyles ? 'Style Verhältnis' :
                        'Stylestärke'}}</label>
                    <input #latentSlider type="range" class="form-range" id="customRange1" [(ngModel)]="styleRatio"
                        [ngModelOptions]="{standalone: true}" step="0.01" min="0" max="1">
                </div>

                <button class="btn btn-primary" type="button" (click)="onStyleSubmit(outputCanvas)"
                    [disabled]="!buttonsEnabled">{{styleText}}</button>
            </form>
        </div>
    </div>
</div>