<div class="container pt-3">
   <div class="row">
      <div class="col">
         <h3>
            Übersetzen
         </h3>
         <p>
            Auf den ersten Blick scheint es einfach zwischen verschiedenen Sprachen zu Übersetzen.
            Für einzelne Wörter ist das oft auch korrekt.
            Sobald wir jedoch komplexe Sätze übersetzen wollen, wird es schnell sehr schwierig, da verschiedene Sprachen
            auch verschiedene Grammatiken voraussetzen, die sehr kompliziert sein können.
         </p>
         <p>
            Aus diesem Grund wird für alle gängigen Online-Dienste, wie zum Beipspiel Google Translate, die Anfrage
            eines Nutzer, der etwas übersetzen möchte, an einen Server gesendet, welcher dann diese komplexe Berechnung
            ausführt.
         </p>
         <p>
            Ein Problem mit deiser Herangehensweise ist die Privatsphäre des Nutzers, die durch die Übermittlung aller
            Anfragen an einen entfernten Server natürlich beeinträchtigt werden kann.
            Wäre es nicht toll wenn es eine Technologie gäbe, die es uns ermöglicht im Browser ein komplexes Modell
            auszuführen, welches es uns ermöglicht beliebige Sätze zu übersetzen.
         </p>
         <p>
            Dank des maschinellen Lernens ist es tatsächlich heutzutage möglich genau dies zu tun. Vorausgesetzt das
            Gerät des Nutzers hat ausreichend Leistung, kann einfach ein trainiertes Modell heruntergeladen werden,
            womit beliebige Sätze übersetzt werden können ohne, dass private Daten an fremde Server übertragen werden.
         </p>
      </div>
   </div>
   <div class="app">
      <div class="panel panel--from">
         <label>
            From <span *ngIf="loadingModel" class="spinner-border spinner-border-sm m-2"></span>
            <select id="lang-from" name="from" class="lang-select" [(ngModel)]="langFrom"
               [disabled]="translating || loadingModel" (change)="updateFromLang()">
               <ng-container *ngFor="let t of supportedTranslations | keyvalue">
                  <option *ngIf="langTo !== t.key" [value]="t.key">{{langs[t.key]}}</option>
               </ng-container>
            </select>
         </label>
         <textarea #input id="input" name="input" [(ngModel)]="inputText"></textarea>
      </div>
      <button class="swap" title="swap" (click)="swap()">↔️</button>
      <div class="panel panel--to">
         <label>
            To <span *ngIf="loadingModel" class="spinner-border spinner-border-sm m-2"></span>
            <select id="lang-from" name="from" class="lang-select" [(ngModel)]="langTo"
               [disabled]="translating || loadingModel" (change)="updateFromTo()">
               <!-- <option *ngFor="let lang of langs | keyvalue" [value]="lang.key"> {{lang.value}} </option> -->
               <option *ngFor="let t of supportedTranslations[langFrom]" [value]="t"> {{langs[t]}} </option>
            </select>
         </label>
         <textarea id="output" name="output" [(ngModel)]="outputText" [disabled]="translating" readonly></textarea>
      </div>
      <div class="footer" id="status"></div>
   </div>
   <div class="row justify-content-center my-3">
      <div class="col-md-8">
         <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="">
            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <span>
                     <h4>Aufgabe 1</h4>
                  </span>
               </ng-template>
               <ng-template ngbPanelContent>
                  Überlege dir einen komplexen deutschen Satz und versuche diesen mit dieser Seite ins Englische zu
                  übersetzen.
                  Vergleiche nun das Ergebnis mit einem online Dienst, wie <a href="https://translate.google.com">Google
                     Translate</a>.
                  Unterscheiden sich die Ergebnisse? Welches Ergebnis würdest du bevorzugen?
                  Welcher Dienst bietet mehr Sprachen für die Übersetzung?
               </ng-template>
            </ngb-panel>
            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <span>
                     <h4>Aufgabe 2</h4>
                  </span>
               </ng-template>
               <ng-template ngbPanelContent>
                  Welche Vorteile entstehen für den Nutzer und den Besitzer der Webseite dadurch, dass ein Modell für
                  die Übersetzung verwendet wird, anstelle eines Servers.
               </ng-template>
            </ngb-panel>
            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <span>
                     <h4>Aufgabe 3</h4>
                  </span>
               </ng-template>
               <ng-template ngbPanelContent>
                  Angenommen es findet eine Änderung in der Grammatik oder Rechtschreibung einer Sprache statt. Was muss geschehen, damit diese Änderungen auch in dem Machine Learning Modell auftauchen?
               </ng-template>
            </ngb-panel>
            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <span>
                     <h4>Aufgabe 4</h4>
                  </span>
               </ng-template>
               <ng-template ngbPanelContent>
                  Neben Vorteilen gibt es natürlich auch Nachteile dieser Methode. Welche Nachteile fallen dir ein, die
                  einen Nutzer eventuell einschränken?
               </ng-template>
            </ngb-panel>
         </ngb-accordion>
      </div>
   </div>
</div>