<main class="main-body">
  <!-- Navbar always visible, except in case of a survey url -->
  <app-navbar *ngIf="!inPath('mnista', 'mnistb')"></app-navbar>
  <div class="pb-3" [ngClass]="{'pt-4': inPath('mnista', 'mnistb')}">
    <router-outlet></router-outlet>
  </div>
</main>
<!-- constant footer -->
<footer class="main-footer px-3">
  <div class="container">
    <div class="row">
      <div class="col-sm-9">
        <p>Erstellt von <a href="https://github.com/lukasboettcher">Lukas Böttcher</a>, weiterentwickelt von der <a
            href="https://www.ddi.inf.uni-kiel.de/de">AG für Didaktik der Informatik CAU</a>. <a
            routerLink="/imprint">Impressum</a></p>
      </div>
      <div class="col-sm-3">
        <img src="assets/branding/tf-norm-de-blackgreyfaku-rgb-0720.png.webp" class="figure-img img-fluid rounded mb-1"
          style="height: 40px; margin-top: -5px;">
      </div>
    </div>
  </div>
</footer>