<div class="container pt-3">
    <div class="row mb-3">
        <div class="col">
            <h3>Landschaftserstellung mit GANs</h3>
            <p>
                Ein weiteres Anwendungs­szenario für Generative Adversarial Networks ist die Bilderstellung basierend
                auf einer semantischen Eingabe.
                Eine semantische Eingabe kann man sich so vorstellen, dass dem Generator als Eingabe nicht nur zufällige
                Daten gegeben werden, sonder ausgewählte Daten, welche die Ausgabe direkt beeinflussen sollen.
            </p>
            <p>
                In diesem Beispiel hier bedeutet dies konkret, dass ein GAN Netzwerk mit sehr vielen Bildern von
                Landschaften trainiert wurde, wobei die einzelnen Elemente der Trainingsbilder in Kategorien unterteilt
                wurden, sodass der Generator lernt, wie z.B. ein Baum in ein Bild einer Wiese passt.
            </p>
            <p>
                Auf dem Canvas unten kannst du aus verschiedenen Kategorien auswählen und einen Sketch zeichnen.
                Wenn du diesen dann an das GAN Modell schickst, versucht dieses ein "echt" aussehendes Bild zu erzeugen.
                Zu Beginn ist auf dem Canvas ein Meer mit Himmel abgebildet, wenn du generieren klickst, sollte das
                Ergebnis unten abgebildet werden.
                Probiere einfach aus das Bild zu verändern, indem du z.B. etwas Sand als Strand unten auf dem Canvas
                einzeichnest.
                Das Ergebnis sollte nun einen Strand abbilden. Zusätzlich kannst du das Bild in verschiedenen Umgebungen
                und Tageszeiten erstellen.
            </p>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-md-9 col-lg-6">
            <app-landscape-canvas #inputCanvas [canvasColor]="canvasColor" [canvasBrushWidth]="canvasBrushWidth"
                [canvasWidth]="canvasWidth" [canvasHeight]="canvasWidth">
            </app-landscape-canvas>
        </div>

        <div class="col-md-3 col-lg-6 my-3">
            <div *ngFor="let cat of categories.categories">
                <div class="d-flex flex-column">
                    <button class="btn btn-dark my-1"
                        (click)="activeCategory = activeCategory === cat.index ? undefined : cat.index">
                        {{cat.name}}
                    </button>
                    <div *ngIf="activeCategory === cat.index" class="topic-grid">
                        <button type="button" class="btn btn-sm topic-button"
                            *ngFor="let topic of categories[cat.index]" (click)="canvasColor = topic.color"
                            [style.background-color]="topic.color" [style.color]="textColor(topic.color)">
                            {{topic.name}}
                        </button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="mb-3">
                <label for="customRange1" class="form-label">Pinselgröße</label>
                <input #brushWidthInput type="range" class="form-range" id="customRange1"  [value]="canvasBrushWidth"
                (change)="updateBrushWidth(brushWidthInput.value)" step="2" min="2" max="200">
            </div>
            <div class="mb-3">
                <div class="form-floating">
                    <select #styleselect class="form-select" id="floatingSelect">
                        <option value="random">Zufälliger Style</option>
                        <option *ngFor="let n of [0,1,2,3,4,5,6,7,8,9,10]" value="{{n}}">
                            Style {{n}}
                        </option>
                    </select>
                    <label for="floatingSelect">Modell Qualität</label>
                </div>
            </div>
            <div class="btn-group" role="group">
                <button class="btn btn-primary" (click)="sendRequest(styleselect.value)">Generieren</button>
                <button class="btn btn-danger" (click)="inputCanvas.resetCanvas()">Zurücksetzen</button>
            </div>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-md-6 d-flex flex-column align-items-center">
            <canvas #outputCanvas id="outputCanvas" width="512" height="512"></canvas>
        </div>

        <div class="col-md-6 text-center my-5">
            <h5>Das Ergebnis!</h5>
            <p>
                Wenn alles funktioniert hat, kannst du das Ergebnis des GAN Netzwerks jetzt sehen.
                Das Bild wurde anhand deiner Zeichnung erstellt und existiert so noch nirgendwo.
                Das bedeutet, das Netzwerk hat etwas generiert, was es noch nie gesehen hat.
            </p>
            <p>
                Du kannst das generierte Bild auch<button type="button" class="btn btn-link btn-sm"
                    (click)="downloadOutput()">herunterladen</button>.

            </p>
        </div>
    </div>
</div>